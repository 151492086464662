// /* MAIN MENU */ //

$nav-base-font        	 	: 1.111rem/1.3 $title-font-family; //1.333rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.3 $title-font-family; //1.111rem/1.3 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: $independence;
$nav-base-bg-color        		: white; 
$nav-base-text-color     		 	: white;
$nav-base-padding        			: 0.675rem 0.889rem;
$nav-base-hover-padding   		: 0.675rem 0.889rem;

$nav-sub-base-padding					: 0.667rem 2.778rem 0.500rem 1.111rem;
$nav-sub-base-hover-padding		: 0.667rem 2.778rem 0.500rem 1.111rem;

$nav-mob-padding		          : 0.825rem 2.778rem 0.8rem 1.111rem;
$nav-mob-sub-padding		      : 0.667rem 2.778rem 0.500rem 1.111rem;

$nav-base-padding-smaller 				: 0.675rem 0.889rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.675rem 0.889rem;

$nav-base-border-color        : transparent;

$nav-active-line	  	        : $active_menu no-repeat 50% 100% / 81px 18px;
$nav-active-hover-line	  	  : $active_menu no-repeat 50% 100% / 81px 18px;

$nav-sub-active-line	  	        : $active_mobile_menu no-repeat 0% 50% / 18px 61px;
$nav-sub-active-hover-line	  	  : $active_mobile_menu no-repeat 0% 50% / 18px 61px;

$nav-active-bg-color      		: $legendary_purple;
$nav-active-text-color    		: white;
$nav-active-border-radius 		: 0; 
$nav-active-border-color	  	: $nav-active-line;

$nav-hover-bg-color       		: $rhythm;
$nav-hover-text-color     		: white;
$nav-hover-border	  	    		: none;
$nav-hover-border-color	  	  : $nav-active-hover-line;

$nav-highlight-bg-color   		: $legendary_purple;
$nav-highlight-text-color 		: white; 
$nav-highlight-border	    		: none;
$nav-highlight-border-color 	: transparent;



$nav-sub-bg-color         		: white;
$nav-sub-text-color       		: $legendary_purple;
$nav-sub-hover-text-color     : $legendary_purple;
$nav-sub-border-color         : transparent;
$nav-sub-hover-text-color     : $primary;

$nav-sub-highlight-bg-color   : $aircraft_white;

$nav-sub-hover-bg-color   		: $sparkling_river;
$nav-sub-hover-text-color   	: $legendary_purple;
$nav-sub-hover-border-color   : transparent;

$nav-sub-active-bg-color      : transparent;
$nav-sub-active-text-color    : $middle_green;
$nav-sub-active-border-color  : transparent;

$nav-sub-active-hover-text-color     : $star_command_blue;
$nav-sub-active-hover-border-color   : transparent;

$nav-sub-current-expanded-text-color     : $middle_green;
$nav-sub-current-expanded-border-color   : transparent;

$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

// /* MOBILE */ //
 
$nav-mob-text-color      						: $legendary_purple;
$nav-mob-active-text-color      		: white;
$nav-mob-sub-active-text-color      : $middle_green;
$nav-mob-active-bg-color      			: inherit;
$nav-mob-active-border-color	  	  : $nav-sub-active-line; //$rusty_red;
$nav-mob-active-hover-border-color	: $nav-sub-active-hover-line; //$dark_cyan;

$nav-mob-current-text-color      		: white;
$nav-mob-current-bg-color      			: $legendary_purple;

$nav-mob-hover-bg-color    					: $sparkling_river;

$nav-mob-1st-bg-color     					: $independence;
$nav-mob-1st-text-color      				: white;  
$nav-mob-1st-hover-bg-color         : $rhythm;
$nav-mob-1st-hover-text-color       : white;
$nav-mob-1st-highlight-bg-color     : $legendary_purple;
$nav-mob-1st-highlight-text-color   : white;
$nav-mob-1st-border-color     			: $rhythm;
$nav-mob-1st-highlight-border-color : $legendary_purple;

$nav-mob-2nd-bg-color     					: white;
$nav-mob-2nd-highlight-bg-color     : $aircraft_white;
$nav-mob-2nd-highlight-text-color   : $independence;
$nav-mob-2nd-line-color     				: $beau_blue;
$nav-mob-2nd-border-color     			: $beau_blue;
$nav-mob-2nd-text-color      				: $independence;

$nav-mob-3rd-bg-color     					: $aircraft_white;
$nav-mob-3rd-highlight-bg-color     : $aircraft_white;
$nav-mob-3rd-highlight-text-color   : $independence;
$nav-mob-3rd-line-color     				: $beau_blue;
$nav-mob-3rd-border-color     			: $aircraft_white;
$nav-mob-3rd-text-color      				: $independence;

$nav-mob-border											: 1px solid $rhythm;
$nav-mob-expanded-border						: 1px solid $rhythm;
$nav-mob-top-border						      : 4px solid $independence;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: $box-shadow; 



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $legendary_purple;
$nav-button-hover-bg-color			: $independence;
$nav-button-height      				: 84px;
$nav-button-width      					: 70px;
$nav-button-bar-bg-color      	: white;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 5px;
$nav-button-bar-width      			: 39px;
$nav-button-bar-border-radius 	: 50px;
