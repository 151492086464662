section .section-row.section-with-promo {
  padding: 0;
  width: 100%;
  position: relative;
  
  .section-inner {
    max-width: none;
    width: 100%;
    padding: 0;
  }

  .view-promo {
    // @media(min-width:$narrow) {
    //   max-width: 1600px;
    // }
    
    .promo {
      width: 100%;
      position:relative;
    
      .card-image { 
        z-index: 10;
        position: relative;
        
        @media (max-width:400px) {
          margin-left: calc(50% - 200px);
        }
        
        @media (min-width:$normal) {
          position: absolute;
          height: 100%;
          right: 50%;
        }
        
        .promo-image {
          
          picture, img {
            max-width: none;
            width: 100%;
            
            @media (min-width:$normal) {
              width: auto;
            }
          }
        }   
      }
      
      
      a.card-promo-text {
        display: block;
        width: 100%;
        z-index: 15;
        position: relative;
        margin: -98px 0 0 0;
        
      
        @media (min-width:$normal) {
          
          & > * { 
            height: 100%;
            min-height: 450px;
          }
          
          margin: 0;
          padding: 0;
          width: calc(50% + 120px);
          float: right;
          display: flex;
          flex-direction: row;
        }

        &:hover {
          filter: brightness(1.1);
        }
        
        p:last-child {
          margin-bottom:0;
        }
        
        .card-promo-text-wrapper {
          padding: 30px 60px;
          display: block;
          width: 100%;
          
          @media (min-width:$normal) {
            padding: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .card-title-field {
            width: 100%;
            max-width: 680px;
      
            .promo-title {
              color:$primary;
              font-size: 1.667rem;
              margin-bottom:20px;
              line-height: 1;
              font-family: $title-font-family;
            }
          }
          .card-description {
            color: $primary;
            width: 100%;
            max-width: 680px;
            font-size: 1.222rem;
          
            h2, h3, h4, h5, h6 {
              color: $primary;
            }
          }
        }
        
        &:before {
          content: "";
          display: block;
          width: auto;
          height: 100px;
          border: none;
          background-repeat: no-repeat !important;
          background-size: 100% 100% !important;
          background: $promo-curve-mobile-orange transparent 50% 50% / auto 100%;
          
          @media (min-width:$normal) {
            width: 125px;
            height: auto;
            background: $promo-curve-desktop-orange transparent calc(100% + 2px) 50% / auto 100%;
          }
        }
      }  
    }
  }

  &.palette-default {
    &,
    & .promo .card-promo-text-wrapper {
      background-color: $cadmium_orange;
    }
    .view-promo .promo a.card-promo-text:before {
      background-image: $promo-curve-mobile-orange;
      
      @media (min-width:$normal) {
        background-image: $promo-curve-desktop-orange;
      }
    }
  }
  &.palette-alt-1 {
    &,
    & .promo .card-promo-text-wrapper {
      background-color: $sizzling_sunrise;
    }
    .view-promo .promo a.card-promo-text:before {
      background-image: $promo-curve-mobile-yellow;
      
      @media (min-width:$normal) {
        background-image: $promo-curve-desktop-yellow;
      }
    }
  }
  &.palette-alt-2 {
    &,
    & .promo .card-promo-text-wrapper {
      background-color: $middle_green;
    }
    .view-promo .promo a.card-promo-text:before {
      background-image: $promo-curve-mobile-green;
      
      @media (min-width:$normal) {
        background-image: $promo-curve-desktop-green;
      }
    }
  }
  &.palette-alt-3 {
    &,
    & .promo .card-promo-text-wrapper {
      background-color: $poppy_red;
    }
    .view-promo .promo a.card-promo-text:before {
      background-image: $promo-curve-mobile-red;
      
      @media (min-width:$normal) {
        background-image: $promo-curve-desktop-red;
      }
    }
  }
  &.palette-highlight {
    &,
    & .promo .card-promo-text-wrapper {
      background-color: $carolina_blue;
    }
    .view-promo .promo a.card-promo-text:before {
      background-image: $promo-curve-mobile-blue;
      
      @media (min-width:$normal) {
        background-image: $promo-curve-desktop-blue;
      }
    }
  }
}