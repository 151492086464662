// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	outline: none;
  padding: 0 2px 4px;
	
	transition: $card-transition;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
}

// TAGGED CONTENT
.card-title-et {	
	h3 {
		font:$card-title-font;
	}
}

.card-display-date {
	// background: $card-date-bg-color;
	// border-bottom:$card-date-border;
	// border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:10px 20px 0;
}


// /* MORE LINK */
.section-row:not(.view-type-slider),
.side-row //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

.side-row .card-more-link {
	width:100%;
	margin-left: 0;
}


// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	padding-bottom: 3px;
	position: relative;
	a {
		&:after {
			content: "";
	    display: inline-block;
	    width: 10px;
	    height: 22px;
	    margin-left: 10px;
	    vertical-align: sub;
		}
		&:before {
			content: "";
	    display: inline-block;
			width: 150px;
	    position: absolute;
	    height: 3px;
	    bottom: 0px;
	    margin: 0;
		}
	}
}

// /* INNER CARD ELEMENTS */
.tweet {
	.card-text {
		display:block;
		font:normal 1.125em/1.22222222222222 $base-font-family;
	}
	.card-tweet-message a:hover {
		text-decoration: $card-hover-decoration;
	}
	.card-date {
		margin-top: 10px;
		font-size: 0.777em;
	}
}

.card-logo {
	background:#FFFFFF;
}

.card-video {
	margin-bottom:15px;
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: normal;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

// .card-start-date {
// 	margin-right:2px;
// 	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
// 	text-align:center;
// 
// 	.card-date-day {
// 		font-size:1.75em;
// 		line-height:0.78571428571429;
// 	}
// 	.card-date-month {
// 		font-size:0.875em;
// 		line-height:1.22222222222222;
// 		text-transform:uppercase;
// 	}
// }

.card-citation {
	font-style:italic;
}

.card-date,
.card-location, 
.card-type,
.card-date-display-override,
.card-resource-type,
.card-content-type,
.card .card-published-by,
.card-journal,
.card-citation-date{
	font-size:0.778rem;
	line-height:140%;
}
.card-date-display-override,
.card-published-by,
.card-date,
.card-journal,
.card-abbr-authors {
	margin-bottom: 10px;
}

.card-job-title {
	font-size:1em;
	line-height:140%;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;


.card-text-wrapper {
	position: relative;
  z-index: 20;
	
	.card-top-date {
    display: flex;
		
		.card-display-date {
		  display: inline-block;
		}
	}
}

.card-with-image {
	.card-text-wrapper {
		.card-top-date {
			margin-top: -25px;
		}
	}
}

// ROW-MAX-1 
@media (min-width: 540px) {
	.row-max-1 {
		article:not(.node-teaser-image),
		.custom-link-flexi {
			.card-with-image {
				.card-text-wrapper	{	
					.card-top-date {
						margin-top: 0;
					}
				}
			}
		}
	}
}

//row-max-1 with sidebars
@media (min-width: 540px) and (max-width:$narrow) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						.card-text-wrapper	{	
							.card-top-date {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width:$narrow) and (max-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						.card-image {
							float: none;
						}
						.card-text-wrapper	{	
							.card-top-date {
								margin-top: -25px;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						.card-text-wrapper	{	
							.card-top-date {
								margin-top: 0;
							}
						}
					}
				}
			}
		}
	}
}

//ROW-MAX-2
//If row-max-2 is selected and the page is more than 960px in width and DONT have sidebar second, show the image on the left
@media (min-width:$normal) {	
	.page-node:not(.sidebar-second) main,
	.postscript-first {
		.section-row:not(.view-type-slider) {
			&.row-max-2 .views-row {
				.card-with-image {
					.card-text-wrapper	{	
						.card-top-date {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
}


// .section-row:not(.view-type-slider) {
// 	.card-image,
// 	.card-photo,
// 	.card-logo {
//     float: none;
// 	}
// }

// .view-news-events {
// 	.node-event .card {
// 		overflow: visible;
// 		margin-top: 30px;
// 	}
// }