button,
input[type="button"],
input[type="reset"],
input[type="submit"] ,
#site-header .header-inner-wrapper .block-menu ul.menu a {
  background-color: $button-bg;
  border: $button-border;
  // border-color: $button-border-color;
  border-radius: $button-border-radius;
  color: $button-text!important;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.444rem 1rem;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
	outline: none;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    border: $button-hover-border;
    background-color: $button-hover-bg;
    color:  $button-hover-text!important;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: $button-border;
  // border-color: $button-border-color;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 0.444rem 1rem;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  letter-spacing: 1px;
}

.form-managed-file .form-file, .form-managed-file .form-submit {
    margin: 5px 0 10px;
}
