// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #31323F; /* black */ 
$secondary         : #6D708B; /* grey */ 



// /* MAIN PALETTE */ //

// $light_blue        : #84D0F5; /* lightblue */
// $middle_red_purple : #38023B; /* middle_red_purple */


// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa; /* pale_grey */
// $pale_grey_two     : #e4e8eb; /* pale_grey_two */
// $battleship_grey   : #6b7280; /* battleship_grey */
// $japanese_indigo   : #6b7280; /* japanese_indigo */
// $platinum          : #e0e0e2; /* platinum */
// $pearl_aqua        : #81D2C7; /* pearl_aqua */  /// MOBILE MENU
// $dark_lavender     : #6250A1; /*  dark_lavender  */  /// MOBILE MENU



$carolina_blue       : #359DCA;
$middle_green        : #A0C262;
$sizzling_sunrise    : #FEDB0A;
$cadmium_orange      : #FA8B23;
$poppy_red           : #DC3839;
$lavender            : #8461B9;
$star_command_blue   : #0078AC;
$green               : #69A300;
$gunmetal            : #31323F;
$legendary_purple    : #4F5165;
$rhythm              : #6D708B;
$silver_sand         : #C0C1C8;
$aircraft_white      : #EDF2F5;
$white               : #FFFFFF;
$sparkling_river     : #D7EBF4;
$independence        : #404252;
$beau_blue           : #CCDAE3;
$maximum_blue        : #5DB1D5;

$black             : #000;
$grey              : #666;
$white             : #FFF;


// MENU
// $jelly_bean					: #E46747;  /// MOBILE MENU
// $middle_red					: #E28871;
// $vivid_tanherine		: #EFA491;
// $champagne_pink			: #F8D9D1;
// $thulian_pink				: #E8789D;
// $queen_pink					: #F5C7D6;
// $pale_cerulean			: #9EB6DC;
// $azureish_white			: #D6E1F0;
// $blue_bell					: #A196C7;
// $languid_lavander		: #D8D3E7;
// $dogwood_rose       : #D81E5B; /* dogwood_rose */ /// MOBILE MENU
// $faded_blue         : #5D86C5; /* faded_blue */ /// MOBILE MENU


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $star_command_blue;
$action-hover        : $middle-green;
$action-active       : $middle-green; 
$action-active-hover : $middle-green; 

$action-alt-default      : white;
$action-alt-hover        : $legendary_purple;
$action-alt-active       : $legendary_purple; 
$action-alt-active-hover : $legendary_purple; 


// /* SHADOWS */ //
$box-shadow : 0 2px 3px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : $beau_blue; //rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
// $table-stripe-bg          : darken($table-bg-color, 4);
// $table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .5rem 1rem;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

$table-border             : 1px solid $aircraft_white;
$table-bg-color           : white;
$table-hover-bg-color     : lighten($table-bg-color, 5%);
// $table-cell-border        : 1px solid $aircraft_white;
$table-cell-text          : $primary;
$table-caption-bg-color   : $aircraft_white;
$table-caption-text-color : $primary;
$table-caption-border     : 1px solid $aircraft_white;
$table-header-bg-color    : $beau_blue;
$table-header-border      : 1px solid $rhythm;
$table-header-cell-border : 1px solid $rhythm;
$table-header-text        : $primary;
$table-text-color       : $primary;

$table-alt-1-bg-color    : $aircraft_white;
$table-alt-1-border       : 1px solid $beau_blue;

$table-alt-2-header-bg-color    : $sparkling_river;


$button-bg             : transparent;
$button-text           : $carolina-blue;
$button-border         : 2px solid $carolina-blue;
$button-border-radius  : 30px;
$button-hover-text     : white;
$button-hover-bg       : $carolina-blue;
$button-hover-border   : $button-border;

$button-alt-1-bg             : transparent;
$button-alt-1-text           : white;
$button-alt-1-border         : 2px solid white;
$button-alt-1-border-radius  : 30px;
$button-alt-hover-text       : $carolina-blue;
$button-alt-hover-bg         : white;
$button-alt-hover-border     : $button-alt-1-border;

$button-alt-2-bg             : transparent;
$button-alt-2-text           : $rhythm;
$button-alt-2-border         : 2px solid $rhythm;
$button-alt-2-border-radius  : 30px;
$button-alt-2-hover-text     : white;
$button-alt-2-hover-bg       : $rhythm;
$button-alt-2-hover-border   : $button-alt-2-text;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $aircraft_white !default;