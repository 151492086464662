.node-type-publication {
	/* side-row detail block */
	.view-display-id-details {
		.detail {
			&:first-child {
				margin-top: 15px;
			}
		}
		
		/* Citation */
		.card-journal,
		.card-citation-date,
		.card-volume,
		.card-pages {
			display: inline;
			font-size: 1rem;
			
			div,
			div::after {
				display: inline;
			}
		}
		
		.card-journal {
			font-family: $title-font-family;
			
			div::after {
				content:",";
				margin: 0 2px 0 0;	
			}
		}
		.card-citation-date,
		.card-year {
			font-weight: normal;
			div::after {
	    	content: ";";
			}
		}
		.card-volume {
			div::after {
				content:":";
				margin: 0 0px 0 2px;	
			}
		}
		
		
		.card-doi,
		.card-pmid {
			clear: left;
		}
		
		.card-link {
			margin-bottom: 5px;
		}
	}

	.card-abstract {
    font-size: 1.222em;
	}
}