.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
	&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
		border-bottom: 4px solid $nav-wrapper-bg-color;
		// @include media(880px) {
		@include media(1100px) {
			border-bottom: 0;
		}
	}
	
	// @include media(880px) {
	@include media(1100px) {
		border: none;
	}

	// /* Change .header-outer-wrapper to
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	
	.header-inner-wrapper {
		@include outer-container($site-max-width);
		position: relative;
	}
	
	#main-navigation {
		@include outer-container($site-max-width);
		position: relative;
		.search-toggle-wrapper {
			position: absolute;
			top:-84px;
			right: 70px;
			display: block;
			// @media(min-width: 880px) {
			@media(min-width: 1100px) {
				top:0;
				right: 0;
				position: relative;
				float: right;
			}
			.search-toggle {
				height: 84px;
				width: 70px;
				background: $search-white no-repeat 50% 50%/ 40px 40px $legendary_purple;
				text-indent: -9999px;
				// @media(min-width: 880px) {
				@media(min-width: 1100px) {
					// height: 42px;
					height: 47px;
					width: 46px;
					background: $search-white no-repeat 50% 50%/ 24px 24px transparent;
				}
				@media(min-width: 1300px) {
					height: 50px;
					width: 50px;
				}
				
				&:hover {
					background: $search-white no-repeat 50% 50%/ 40px 40px $rhythm;
					color: white;
    			cursor: pointer;
					// @media(min-width: 880px) {
					@media(min-width: 1100px) {
						background: $search-white no-repeat 50% 50%/ 24px 24px $rhythm;
					}
				}
				
				&.active {
					background: $close-search-white no-repeat 50% 50%/ 40px 40px $independence;
					color: white;
					// @media(min-width: 880px) {
					@media(min-width: 1100px) {
						background: $close-search-white no-repeat 50% 50%/ 24px 24px $legendary_purple;
					}
					
					&:hover {
						background: $close-search-white no-repeat 50% 50%/ 40px 40px $rhythm;
						color: white;
						// @media(min-width: 880px) {
						@media(min-width: 1100px) {
							background: $close-search-white no-repeat 50% 50%/ 24px 24px $rhythm;
						}
					}
				}
			}
		}
		.block-search {
			position: absolute;
			display: block;
			right: 0;
			// @media(max-width: 879px) {
			@media(max-width: 1099px) {
				width:100%;
			}
			#search-block-form--2 { 
				display: none;
				position: absolute;
				right: 0;
				top: 4px;
				background: white; 
				z-index: 1;
				padding: 5px;
				height:60px;
				max-width: none;
				box-shadow: $header-search-shadow;
				// @media(max-width: 879px) {
				@media(max-width: 1099px) {
					width:100%
				}
				.form-item {
					margin:0;
					// @media(max-width: 879px) {
					@media(max-width: 1099px) {
						width:100%
					}
				}
				.form-item-search-block-form input.form-text {
					width:320px;
					background: white;
					border-radius: 0;
					// border-bottom: solid 2px white;
					height: 50px;
					color: $primary;					
    			padding-right: 50px;
					@media(max-width: 879px) {
						width:100%;
						max-width: 100%;
					}
					&::placeholder {
						color:$secondary;
					}
				}
				.form-wrapper {
					display:block;
					// @include media(880px) {
					@include media(1100px) {
						display:block;
					}
				}
				h2 {
					display: none;
				}
				input[type="submit"] {
					text-indent: -9999px;
					position: absolute;
					top: 6px;
					right: 10px;
					background: $search-blue no-repeat 50% 50%/ 25px 25px transparent;
					&:hover {
						background: $search-hover no-repeat 50% 50%/ 25px 25px transparent;
					}
				}
				
				// @media(min-width: 880px) {
				@media(min-width: 1100px) {
					top: 46px;
				}
				@media(min-width: 1151px) {
					top:50px;
				}
			}
		}
	}
	
	.header-inner-wrapper {	
		// @media (max-width:879px) {
		@media (max-width:1099px) {
	    display: flex;
	    flex-direction: column-reverse;
		}
		
		.wrapper-site-branding {
			float: left;
			
			.site-branding {
				//margin-top:2.5rem;
				margin-left:1.25rem;
				position: relative;
				// @include media(880px) {
				// 	top:0;
				// }
				.site-title {
					// position:absolute;
					// left:0;
					margin-top:0;
					
					// @include media(880px) {
					@include media(1100px) {
						margin-top:20px;
					}
					
					a {
						background-size:contain;
						display:block;
						text-indent:-999em;
						overflow:hidden;
						width:200px;
						height:68px;
						
						@media(max-width:$narrow) {
							max-width: 200px;
							width: calc(100% - 150px);
							background-position: 0 50%;
						}

						// @include media(540px) {
						// @include media(880px) {
						@include media(1100px) {
							width:280px;
							height:95px;
						}
					}
				}
				.site-slogan {
					position:absolute;
					left:235px;
					top:2.5rem;
				}
			}
		}
		
		.wrapper-region-header {
			display: flex;
			float: right;
			justify-content: flex-end;
			
			.block-search {
				display: none !important;
				
				height:10rem;
				// @include media(880px) {
				@include media(1100px) {
					height:auto;
				}
				
				h2 {
					display: none;
				}
				.form-item-search-block-form {
					margin-top:40px;
					margin-right:1.125rem;
					
					@media (min-width: 1150px) {
						margin-right:1.5625rem;
					}
					
					margin-bottom:30px;
					float:left;
					
					input.form-text {
						width:10rem;
						height:1.875rem;
						background-color:$header-search-field-bg-color;
						color:$header-search-field-text-color;
						box-shadow:$header-search-field-box-shadow;
						border:$header-search-field-border;
						border-radius:$header-search-field-border-radius;
						font:$header-search-field-font;
						padding:0.375rem 0.75rem;
					}
				}
				.form-actions {
					float:left;
					margin-top:0.75rem;
					input.form-submit {
						background: transparent $search-white no-repeat scroll 0 0;
						background-size:14px;
						width:0.875rem;
						height:0.875rem;
						text-indent:-999em;
						overflow:hidden;
						padding:0;
						position:absolute;
						right:1.25rem;
						margin-right:0.325rem;
						
						@media (min-width: 1150px) {
							margin-right:0.7625rem;
						}
						
						top:50px;
						border: none;
					}
				}
			}

			.block-menu {
				margin-top:24px;
				
				// @include media(880px) {
				@include media(1100px) {
					margin-top:27px;
				}
				
				ul.menu {
					margin: 0;
					
					li {
						display:inline-block;
						margin:0 0.75rem 0 0;
						
						// @include button-top-menu-alt();			
					}

					a {
						font:$header-menu-font;
						color: $header-menu-link-color;
						
						&:hover {
							color: $header-menu-link-hover;
						}
					}
				}			
				
				&.block-system {				
					ul.menu {
						padding-left: 0;
						margin-left: 5px;
						// li:last-child {					
						// 	@include button-top-menu-default();
						// }
					}
				}	
			}
			
			.block-locale {
				clear:both;
				display:none;
			}
			
			.kings_logo_block {
				.kings_college_london_logo {
					margin: 10px 20px 20px 20px;
					height: 54px;
					width: 70px;
					display: block;
					position: relative;
					content: "";
					background: transparent $logo-kings no-repeat scroll 0 0/100% 100%;
					
					// @include media(880px) {
					@include media(1100px) {
						margin: 20px 30px 30px 40px;
						height: 77px;
						width: 100px;
					}
				}
			}
		}
	}
}
