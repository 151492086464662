//@import url(https://fonts.googleapis.com/css?family=Oswald:400,700|Roboto:400,400i,700,700i);
@import url(../fonts/fonts.css);

$base-font-family      		: 'Gill Sans MT W01 Light', sans-serif;
$title-font-family    		: 'Gill Sans MT W01 Medium', sans-serif;
$subtitle-font-family 	 	: 'Gill Sans MT W01 Medium', sans-serif;

$base-font-size						: 18px;
$base-line-height					: 1.3;

$base-font-size-normal		: 18px;

$button-link-font 				: normal 1.111rem/1 $title-font-family;

/* FORMS */
$button-font 							: normal 1.111rem/1 $title-font-family;

/* FORMS */
$node-label-font 				  : normal 1.333rem/1 $title-font-family;

/* FAQS */
$faq-question-font        : normal 1.333rem/1 $title-font-family;

/* ATTACHMENTS */
$attachments-title-font 	: normal 1.111rem/1 $title-font-family;