html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
	// color:$primary;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$base-font-family;
	}
	&.card-title-sm {
		font-family:$base-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:normal;
}
h3, h4, h5, h6 {
	font-weight:normal;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$base-font-family;
	}
}
h1 {
	font-size:2rem;
	line-height:1.2;
	margin-bottom:$vert-space;
	
	@include media($normal) {
		font-size:2.222rem;
		line-height: 1.3;
	}
}
h2 {
	font-size:1.778rem;
	line-height:1.2;
	margin-bottom:0.5em;
	// font-weight: normal;

	@include media($normal) {
		font-size:2.000rem;
		line-height: 1.3;
	}
}
h3 { 
	font-size:1.556rem;
	line-height:1.2; /*1.273;*/
	margin-bottom:0.5rem;
	
	@include media($normal) {
		font-size:1.778rem;
		line-height: 1.3;
	}
}
h3.block__title {
	
	font-size:1.556em;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 1.778rem;
	}
}
h4 {
	font-size:1.444rem;
	line-height:1.2; /*1.3;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.556rem;
		line-height: 1.3;
	}
}
h5 {
	font-size:1.333rem;
	line-height:1.2; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.444rem;
		line-height: 1.3;
	}
}
h6 {
	font-size:1.222rem;
	line-height:1.2; /*1.3;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.333rem;
		line-height: 1.3;
	}
}
p {
	margin:0 0 $vert-space;
}
main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;
			
			&.smaller-text {
				font-size: 0.778rem;
				line-height: 1.3;
				
				p, li {
					font-size: 1em;
				}
			}
			&.larger-text {
				font-size: 1.222rem;
				line-height: 1.3;
				p, li {
					font-size: 1em;
				}
			}				
			@include media($normal) {
				font-size:1rem;
				line-height: 1.3;
				
				&.smaller-text {
					font-size: 0.778rem;
					line-height: 1.3;
				}
				&.larger-text {
					font-size: 1.222rem;
					line-height: 1.3;
				}				
			}
		}
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;

			p {
				font-size: 0.778rem;
				line-height: 1.3;

				@include media($normal) {
					font-size:0.778rem;
					line-height: 1.3;
				}
			}
		}
	}
}
b, strong {
	font-weight:600;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}
	
	&:not([href]) {
		color: inherit;
	}
}

.date,
.card-date,
.card-date-display-override {
	font-size:0.778rem;
	font-style:normal;
	font-family:$title-font-family;
}

em, i {
	font-style: italic;
}

sup, sub {
	font-size: smaller;
	&:before {
		content: "";
		margin-left: 0.25em;
	}
}
sup { vertical-align: super; }
sub { vertical-align:sub; }


// QUOTES 

blockquote {
	position: relative;
	margin: 0 auto;
	width:60%;
	min-height: 50px;
	margin-bottom: 20px;
	font-size: 1.667rem;
	font-family: $title-font-family;
	line-height: 1.3;
	
	p {
		font-size: 1.667rem !important;
	}
	
	// @include media($normal) {
		// width:60%;
		// font-size: 1.667rem;
	// }
	&:before,
	&:after {
		display: block;
		position: absolute;
		content: "";
		height: 36px;
		width: 49px;
	}
	&:before {
		left: -65px;
		top: -7px;
		background: transparent $quote-open-green no-repeat scroll 0 0/100% 100%;
	}
	&:after {
		right: -65px;
		bottom: 0;
		background: transparent $quote-close-green no-repeat scroll 0 0/100% 100%;
	}
}

.side-row blockquote {
	width:80%;
	@include media($narrow) {
		width:65%;
	}
}

/* palette specific styles */

.section-row {
	&.palette-highlight {
		blockquote {
			&:before {
				background: transparent $quote-open-blue no-repeat scroll 0 0/100% 100%;
			}
			&:after {
				background: transparent $quote-close-blue no-repeat scroll 0 0/100% 100%;
			}
		}
	}
}
