.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;  
	}
	
	// .views-row {
	// 	padding: $vert-space/2 0;
	// }
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			// float:left;
			// margin:0 $horz-space 0 0;
			// min-width:4rem;
			margin-bottom: $vert-space*0.75;
			
			.file-extension {
				padding:$vert-space/2 $horz-space/2;
				// text-transform:uppercase;
				font-size:1.111rem;
				font-family: $title-font-family;
        line-height:1;
				text-align:left;
				// font-weight:bold;
				display: inline-block;
			}
			.file-size {
        padding:$vert-space/2 $horz-space/2;
				font-size:1rem;			
				text-align:left;
				// color:$secondary;
        // border:$secondary-border;
        border-top:none;
				display: inline-block;
			}
		}
		
		.file-name {
			overflow:hidden;
			font: $attachments-title-font;
		}
	}

	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
      color:white;
    }
  }
	.file-extension {
		color: white !important;
	}
} 

// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;
	}
}

.no-sidebar	.content-main .view-attachments,
.no-sidebar	.content-additional .view-attachments,
.postscript-first .view-attachments {	
	.views-row {	
		@media (min-width:720px) {
			margin-left: 2.35765%;
  		width: 48.82117%;
			
			@media(min-width: 720px) and (max-width:959px) {
				&:nth-child(2n+1){
					clear:left;
					margin-right: 0;
				}
			}
		}
		
		@media (min-width:960px) {
			margin-left: 2.35765%;
  		width: 31.76157%;
			
			@media(min-width: 960px) and (max-width:1199px) {
				&:nth-child(3n+1){
					clear:left;
					margin-left: 0;
				}
			}
		}
		
		@media (min-width:1200px) {
			margin-left: 2.35765%;
  		width: 23.23176%;				
			
			&:nth-child(4n+1){
				clear:left;
				margin-left: 0;
			}
		}			
	}
}
	
.side-row {
	.view-attachments {
		.views-row {
			width:100%;
			float: left;
			margin: 0 !important;
			clear: both !important;
			// .attachment-link {
			// 	.file-icon {
			// 		@media(min-width: 720px) and (max-width:960px) {
			// 			right:calc(0% - 35%)
			// 		}
			// 	}
			// 
			// 	.file-name {
			// 		@media(min-width: 720px) and (max-width:960px) {
			// 			text-align:center;
			// 			overflow: visible;
			// 			margin-top:60px;
			// 			padding:20px 0 0;
			// 		}
			// 	}
			// }
		}
	}
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments {
			.views-row {
				width:100%;
				
				@media (min-width:960px) {
					margin-left: 2.35765%;
	    		width: 48.82117%;
					
					&:nth-child(2n+1){
						clear:left;
						margin-left: 0;
					}
				}
			}
		}
	}
}