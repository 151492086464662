// /* FILTERS  */ //

$form-label-font                 : normal 1.333rem/1 $title-font-family;

$filter-bg-default               : $beau_blue;
$filter-text-colour-default      : $primary;
$input-bg-default                : $white;

$filter-bg-alt-1                 : $aircraft_white;
$filter-text-colour-alt-1        : $primary;
$input-bg-alt-1                  : $white;

$filter-bg-alt-2                 : $white;
$filter-text-colour-alt-2        : $primary;
$input-bg-alt-2                  : $aircraft_white;

// /* INPUTS  */ //
$fieldset-border-color           : $aircraft_white;
$form-box-shadow                 : none; //$box-shadow;
$form-box-shadow-focus           : none; //$box-shadow; 
$form-border-color               : none; //$secondary-border-color;
$form-border                     : none; //1px solid $secondary-border-color;
$form-border-radius              : 30px;
$form-input-height               : 30px;
$form-select-arrow               : $arrow-filter no-repeat 100% 50% / 30px 30px;
$form-font-size                  : 0.778rem;

// /* BUTTONS  */ //
$submit_bg                       : transparent;
$submit_color                    : $carolina_blue;
$submit_border                   : 2px solid $carolina_blue;
$submit_hover_bg                 : $carolina_blue;
$submit_hover_color              : $white;
$submit_hover_border             : 2px solid $carolina_blue;

$reset_bg                        : transparent;
$reset_color                     : $rhythm;
$reset_border                    : 2px solid $rhythm;
$reset_hover_bg                  : $rhythm;
$reset_hover_color               : $white;
$reset_hover_border              : 2px solid $rhythm;

$form_border_radious             : 40px;
