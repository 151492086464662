.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	display: flex;
	justify-content: center;
	
	@media screen and (max-width: $narrow) {
		font-size: 1rem;
	}
	a {
		padding:0.25em 0.5em;
		
		&:hover {
			color:$green;
		}
	}
	
	li.pager-next,
	li.pager-last,
	li.pager-previous,
	li.pager-first {
		width: 50px;
		height: 50px;
		padding: 0;
		margin: 0 5px;
		
		a {
			display: block;
			background: transparent $pager-first-blue no-repeat scroll 50% 50% / 50px 50px;
			width: 50px;
			height: 50px;
			text-indent: -9999px;
		}
	}
	
	// li.pager-item.pager-next,
	li.pager-next {
		margin-left: $horz-space;
		a {
			background-image: $pager-next-blue;
			
			&:hover {
				background-image: $pager-next-green;
			}
		}
	}
	// li.pager-item.pager-last,
	li.pager-last  {
		a {
			background-image: $arrow-last-blue;
			
			&:hover {
				background-image: $arrow-last-green;
			}
		}
	}
	// li.pager-item.pager-previous,
	li.pager-previous {
		margin-right: $horz-space;
		a {
			background-image: $pager-left-blue;
			
			&:hover {
				background-image: $pager-left-green;
			}
		}
	}
	// li.pager-item.pager-first,
	li.pager-first {
		a {
			background-image: $pager-first-blue;
			
			&:hover {
				background-image: $pager-first-green;
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0;
		font-size: 1.556rem;
		font-family: $title-font-family;
		font-weight: normal;
		color: $star_command_blue;
		padding: 0.25em;
		
		a {
			color:$star_command_blue;
			
			&:hover {
				color:$green;
			}
		}
	}
	li.pager-current {
		color:$legendary_purple;
	}
}

.palette-highlight {
	.item-list  ul.pager {
		// li.pager-item.pager-next,
		li.pager-next {
			a {
				background-image: $pager-next-white;
				
				&:hover {
					background-image: $pager-next-dark;
				}
			}
		}
		// li.pager-item.pager-last,
		li.pager-last  {
			a {
				background-image: $arrow-last-white;
				
				&:hover {
					background-image: $arrow-last-dark;
				}
			}
		}
		// li.pager-item.pager-previous,
		li.pager-previous {
			a {
				background-image: $pager-left-white;
				
				&:hover {
					background-image: $pager-left-dark;
				}
			}
		}
		// li.pager-item.pager-first,
		li.pager-first {
			a {
				background-image: $pager-first-white;
				
				&:hover {
					background-image: $pager-first-dark;
				}
			}
		}
		li,
		li.pager-item {
		
		a {
			color:white;
			
			&:hover {
				color:$legendary_purple;
			}
		}
		}
		li.pager-current {
			color:white;
			background-color: $star_command_blue;
			border-radius: 30px;
	    width: 40px;
	    height: 40px;
	    padding: 0.15rem 0;
	    margin: 0.3rem 0.25rem 0;
		}
	}
}