// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: transparent $pager-left-blue no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg      				: transparent $pager-left-green no-repeat scroll 50% 50%;
$slick-default-next-bg            				: transparent $pager-next-blue no-repeat scroll 50% 50%;
$slick-default-next-hover-bg      				: transparent $pager-next-green no-repeat scroll 50% 50%;
$slick-default-dot-border         				: 1px solid $action-default;
$slick-default-dot-hover-border    				: 1px solid $action-hover;
$slick-default-dot-bg-color   				    : transparent;
$slick-default-dot-hover-bg-color   	    : $action-active;
$slick-default-dot-active-bg-color 				: $legendary_purple;
$slick-default-dot-active-hover-bg-color 	: $action-active;
$slick-default-dot-active-border         	: 1px solid $legendary_purple;
$slick-default-dot-active-hover-border    : 1px solid $action-active;


$slick-alt-1-nav-bg                				: transparent; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: transparent $pager-left-white no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         				: transparent $pager-left-dark no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               				: transparent $pager-next-white no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         				: transparent $pager-next-dark no-repeat scroll 50% 50%;
$slick-alt-1-dot-default-border    				: 1px solid $action-alt-default;
$slick-alt-1-dot-hover-border      				: 1px solid $action-alt-hover;
$slick-alt-1-dot-bg-color   				      : transparent;
$slick-alt-1-dot-hover-bg-color   	      : $action-alt-hover;
$slick-alt-1-dot-active-bg-color 				  : $action-alt-default;
$slick-alt-1-dot-active-hover-bg-color 	  : $action-alt-hover;
$slick-alt-1-dot-active-border         	  : 1px solid $action-alt-default;
$slick-alt-1-dot-active-hover-border      : 1px solid $action-alt-hover;


$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: transparent $slider-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: transparent $slider-left-hover no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: transparent $slider-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: transparent $slider-right-hover  no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: 1px solid white;
$slick-alt-2-dot-hover-border      				: 1px solid $gunmetal;
$slick-alt-2-dot-bg-color   				      : transparent;
$slick-alt-2-dot-hover-bg-color   	      : $gunmetal;
$slick-alt-2-dot-active-bg-color 				  : white;
$slick-alt-2-dot-active-hover-bg-color 	  : $gunmetal;
$slick-alt-2-dot-active-border         	  : 1px solid white;
$slick-alt-2-dot-active-hover-border      : 1px solid $gunmetal;




// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : $poppy_red; //rgba(43, 45, 66, 0.9) ;
$slide-text-color          : white;
$slide-text-font           : 1.667rem/1.2 $title-font-family;
$slide-text-font-small     : 1.667rem/1.2 $title-font-family;