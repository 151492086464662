 // /* HEADER */ //

$header-menu-font 				: 1rem/1 $base-font-family;
$header-search-field-font : 1rem/1.286 $base-font-family;

$header-bg-color                   : none;

$header-menu-link-color            : $primary;
$header-menu-link-hover            : $action-hover;

$header-search-field-bg-color      : $pale_grey;
$header-search-field-text-color    : $primary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;
$header-search-shadow              : 0px 3px 4px rgba(49, 50, 63, 0.12);
