.card-tagline-text-wrapper  {
  .card-tagline-text {
    font:$tagline-font;
    width: 100%;
    margin: 0 auto;
    padding: 30px 50px;
    
    p:last-child {
      margin-bottom: 0; 
    }
  }
  
  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 20px;
    border-top: 10px solid $tagline-bg-default;
  }
  &:after {
    border-top: none;
    border-bottom: 10px solid $tagline-bg-default;
  }
  
  @include media($normal) {
    display: flex;
    align-items: stretch;    
    padding: 0 40px;
    
    & > * {
      height: 100%;
    }
    
    .card-tagline-text {
      max-width: auto;
      margin: 0;
      width: calc(100% - 400px);
      min-height: 245px;
      
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
  
    &:before,
    &:after {
      width: 200px;
      height: auto;
      border: none;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
    }
    
    &:before {
      background: $tagline-left-curve-default transparent 100% 50% / auto 100%;
    }
    &:after {
      background: $tagline-right-curve-default transparent 0 50% / auto 100%;
    }
  }
}

.one-sidebar {
  .content-main,
  .content-additional {
    @include media($normal) {
      .card-tagline-text-wrapper  {
        display: block;
        align-items: center;
        padding: 0;
        
        .card-tagline-text {
          max-width: 100%;          
          margin: 0 auto;
          width: 100%;
          min-height: none;
        }
        
        & > * {
          height: auto;
        }
        
        &:before,
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 20px;
          border-top: 10px solid;
          background: none !important;
        }
        &:after {
          border-top: none;
          border-bottom: 10px solid;
        }
      }
    }
  }  
}

@media (max-width:$normal) {
  .no-sidebar .content-main,
  .no-sidebar .content-additional,
  .postscript-first {
    .section-with-tagline {
      .section-inner {
        max-width: 100%;
        padding: 0;
      }
    }
  }
}

// PALETTE SPECIFIC
​
.content-main,
.content-additional {
  .card-tagline-text-wrapper {
    .card-tagline-text {
      background:$tagline-bg-default;
      color:$tagline-color-default;
      
      & > * {
        color:$tagline-color-default;
      }
    }
    &:before,
    &:after {
      border-color: $tagline-bg-default;
    }
    @include media($normal) {
      &:before {
        background-image: $tagline-left-curve-default;
      }
      &:after {
        background-image: $tagline-right-curve-default;
      }
    }
  }
}
​
.postscript-first .section-row {
  &.palette-default {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-default;
        color:$tagline-color-default;
        
        & > * {
          color:$tagline-color-default;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-default;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-default;
        }
        &:after {
          background-image: $tagline-right-curve-default;
        }
      }
    }
  }
  &.palette-alt-1 {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-alt-1;
        color:$tagline-color-alt-1;  
        
        & > * {
          color:$tagline-color-alt-1;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-alt-1;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-alt-1;
        }
        &:after {
          background-image: $tagline-right-curve-alt-1;
        }
      }
    }
  }
  &.palette-alt-2 {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-alt-2;
        color:$tagline-color-alt-2;
        
        & > * {
          color:$tagline-color-alt-2;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-alt-2;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-alt-2;
        }
        &:after {
          background-image: $tagline-right-curve-alt-2;
        }
      }
    }
  }
  &.palette-alt-3 {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-alt-3;
        color:$tagline-color-alt-3;
        
        & > * {
          color:$tagline-color-alt-3;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-alt-3;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-alt-3;
        }
        &:after {
          background-image: $tagline-right-curve-alt-3;
        }
      }
    }
  }
  &.palette-highlight {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-highlight;
        color:$tagline-color-highlight;
        
        & > * {
          color:$tagline-color-highlight;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-highlight;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-highlight;
        }
        &:after {
          background-image: $tagline-right-curve-highlight;
        }
      }
    }
  }
}

.section-row {
  &.palette-default {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-default;
        color:$tagline-color-default;
        
        & > * {
          color:$tagline-color-default;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-default;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-default;
        }
        &:after {
          background-image: $tagline-right-curve-default;
        }
      }
    }
  }
  &.palette-alt-1 {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-alt-1;
        color:$tagline-color-alt-1; 
        // border: 4px solid $tagline-accent-alt-1; 
        
        & > * {
          color:$tagline-color-alt-1;
        }
      } 
      &:before,
      &:after {
        border-color: $tagline-bg-alt-1;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-alt-1;
        }
        &:after {
          background-image: $tagline-right-curve-alt-1;
        }
      }
    }
  }
  &.palette-alt-2 {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-alt-2;
        color:$tagline-color-alt-2;
        // border: 4px solid $tagline-accent-alt-2;
        
        & > * {
          color:$tagline-color-alt-3;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-alt-2;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-alt-2;
        }
        &:after {
          background-image: $tagline-right-curve-alt-2;
        }
      }
    }
  }
  &.palette-alt-3 {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-alt-3;
        color:$tagline-color-alt-3;
        // border: 4px solid $tagline-accent-alt-3;
        
        & > * {
          color:$tagline-color-alt-3;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-alt-3;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-alt-3;
        }
        &:after {
          background-image: $tagline-right-curve-alt-3;
        }
      }
    }
  }
  &.palette-highlight {
    .card-tagline-text-wrapper  {
      .card-tagline-text {
        background:$tagline-bg-highlight;
        color:$tagline-color-highlight;
        // border: 4px solid $tagline-accent-highlight;
        
        & > * {
          color:$tagline-color-highlight;
        }
      }
      &:before,
      &:after {
        border-color: $tagline-bg-highlight;
      }
      @include media($normal) {
        &:before {
          background-image: $tagline-left-curve-highlight;
        }
        &:after {
          background-image: $tagline-right-curve-highlight;
        }
      }
    }
  }
}