// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $title-font-family;
$card-title-font        	: normal 1.222rem/1.3 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.222rem/1 $title-font-family;
$card-feed-link-font			: normal 1.111rem/1 $title-font-family;


$card-title-big-font      : normal 1.667rem/1.3 $card-title-font-family;

$card-filter-hover        : brightness(0.95);

$card-shadow                   : none; //$box-shadow;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : white;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $primary;
$card-date-font                : 0.8125em/1 $title-font-family;

$card-date-alt-1-bg-color            : $aircraft_white;
$card-date-alt-1-text-color          : $primary;

$card-date-alt-2-bg-color            : $star_command_blue;
$card-date-alt-2-text-color          : white;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-decoration           : underline;

$card-hover-bg-color           : white;
$card-hover-border             : none;
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $carolina_blue; //colour of card title text when you hover over it
$card-text-hover-color         : $secondary; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $primary;
$card-date-hover-bg-color      : white; // allows display dates (e.g. events) to have different hover background from the rest of the card

$card-hover-alt-1-bg-color           : $aircraft_white;
$card-hover-alt-1-border             : none;
$card-hover-alt-1-shadow             : $box-shadow;
$card-action-alt-1-hover             : $carolina_blue; //colour of card title text when you hover over it
$card-text-alt-1-hover-color         : $secondary; //colour of regular card text when you hover over it
$card-date-alt-1-hover-text-color    : $secondary;
$card-date-alt-1-hover-bg-color      : $aircraft_white; 

$card-hover-alt-2-bg-color           : $carolina_blue;
$card-hover-alt-2-border             : none;
$card-hover-alt-2-shadow             : $box-shadow;
$card-action-alt-2-hover             : white; //colour of card title text when you hover over it
$card-text-alt-2-hover-color         : white; //colour of regular card text when you hover over it
$card-date-alt-2-hover-text-color    : white;
$card-date-alt-2-hover-bg-color      : $carolina_blue; 
/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $primary;
$card-light-action-default     : $carolina_blue;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $secondary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $aircraft_white;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $primary;
$card-medium-action-default    : $carolina_blue;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $star_command_blue;
$card-dark-border-color        : transparent;
$card-dark-title-color         : white;
$card-dark-action-default      : white;
$card-dark-action-hover        : $carolina_blue; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : white;
$card-dark-subtitle-color      : white;

// // // /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
// $card-highlight-bg-color       : white;
// $card-highlight-border-color   : transparent;
// $card-highlight-title-color    : $primary;
// $card-highlight-action-default : $carolina_blue;
// $card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
// $card-highlight-text-color     : $secondary;
// $card-highlight-subtitle-color : $secondary;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : white;
$card-more-link-default-color       : $primary;
$card-more-link-default-icon        : $arrow-black-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg    : white;
$card-more-link-default-hover-color : $carolina_blue;
$card-more-link-default-hover-icon  : $arrow-blue-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg              : $aircraft_white;
$card-more-link-alt-color           : $primary;
$card-more-link-alt-icon            : $arrow-black-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $aircraft_white;
$card-more-link-alt-hover-color     : $carolina_blue;
$card-more-link-alt-hover-icon      : $arrow-blue-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-2-bg              : $star_command_blue;
$card-more-link-alt-2-color           : white;
$card-more-link-alt-2-icon            : $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-2-hover-bg        : $carolina_blue;
$card-more-link-alt-2-hover-color     : white;
$card-more-link-alt-2-hover-icon      : $arrow-white-right no-repeat 100% 100% / 14px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : none; // background for the entire link
$card-feed-link-default-color       : $carolina_blue;
$card-feed-link-default-icon        : transparent $arrow-blue-right no-repeat scroll 50% 50% / 15px 15px; //background for the default :after component
$card-feed-link-default-hover-bg    : none;
$card-feed-link-default-hover-color : $action-hover;
$card-feed-link-default-hover-icon  : transparent $arrow-green-right no-repeat scroll 50% 50% / 15px 15px;

$card-feed-link-alt-bg              : none; // background for the entire link
$card-feed-link-alt-color           : $white;
$card-feed-link-alt-icon            : transparent $arrow-white-right no-repeat scroll 50% 50% / 15px 15px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $legendary_purple;
$card-feed-link-alt-hover-icon      : transparent $arrow-dark-right no-repeat scroll 50% 50% / 15px 15px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : transparent;
$button-link-default-border-color       : $carolina_blue;
$button-link-default-color              : $carolina_blue;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $carolina_blue;
$button-link-default-hover-border-color : $carolina_blue;
$button-link-default-hover-color        : white;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : transparent;
$button-link-alt-border-color           : white;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : white;
$button-link-alt-hover-border-color     : white;
$button-link-alt-hover-color            : $carolina_blue;
$button-link-alt-hover-icon             : none;