// /* TAGLINES PALETTE */
$tagline-font                  : 1.667rem/1.3 $title-font-family;
$tagline-font-smaller          : 1.444rem/1.4 $title-font-family;

$tagline-bg-default            :white;
$tagline-color-default         :$primary;
$tagline-accent-default        :none;
$tagline-left-curve-default    :$intro-curve-left-white;
$tagline-right-curve-default   :$intro-curve-right-white;

$tagline-bg-alt-1              :$aircraft-white;
$tagline-color-alt-1           :$primary;
$tagline-accent-alt-1          :none;
$tagline-left-curve-alt-1      :$intro-curve-left-light;
$tagline-right-curve-alt-1     :$intro-curve-right-light;

$tagline-bg-alt-2              :$sparkling-river;
$tagline-color-alt-2           :$primary;
$tagline-accent-alt-2          :none;
$tagline-left-curve-alt-2      :$intro-curve-left-dark;
$tagline-right-curve-alt-2     :$intro-curve-right-dark;

$tagline-bg-alt-3              :white;
$tagline-color-alt-3           :$primary;
$tagline-accent-alt-3          :none;
$tagline-left-curve-alt-3      :$intro-curve-left-white;
$tagline-right-curve-alt-3     :$intro-curve-right-white;

$tagline-bg-highlight          :white;
$tagline-color-highlight       :$primary;
$tagline-accent-highlight      :none; 
$tagline-left-curve-highlight  :$intro-curve-left-white;
$tagline-right-curve-highlight :$intro-curve-right-white;