// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:20;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		height: 50px;
		width: 50px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:1.25rem;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:1.25rem;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;

	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 15px;
	}

	.slick-dots {
		bottom:17px;
	}
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;

		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}

	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}


// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }

	button.slick-arrow {
		padding: 25px;
	}
	.slick-dots {
		bottom:34px;
	}
}






// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;

			&:before {
				content: "";
				display: block;
				height: 100%;
				width: calc(100% - 70px);
				position: absolute;
				//background-color: $slide-text-bg-color;
				left: 35px;
				bottom: 0;

				// @media(min-width:720px) {
				// 	width: calc(100vw - 107px);
				// 	left: 45px;
				// }

				@include media($narrow) {
					display: none;
				}
			}

			.slick-track {
				// background-color:$slide-text-bg-color;

				// @include media($narrow) {
				// 	background-color: transparent;
				// }

				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;

						@include media($narrow) {
							max-height: 100%;
					    height: 420px;
					    width: auto;
						}

						@include media($normal) {
							height: 480px;
						}

						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								max-width:none;
								height: 100% !important;
								width: auto;
								left:calc(50% - 480px);
							}

							@include media($normal) {
								left:calc(50% - 600px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
							}

							@include media(1700px) {
								left: -10px;
    						width: calc(100% + 20px);
								height: auto !important;
							}



							@media screen and (-webkit-min-device-pixel-ratio:0) {
								@include media(720px) {
									max-width: none;
									height: 460px !important;
									width: auto;
									left: calc(50% - 400px);
								}
								@include media(810px) {
									max-width:none;
									height: 440px !important;
									width: auto;
									left:calc(50% - 490px);
								}
								@include media(960px) {
									left: calc(50% - 580px);
									height: 500px !important;
									width: auto !important;
								}
								@include media(1080px) {
									left: calc(50% - 650px);
									height: 500px !important;
									width: auto !important;
								}
								@include media($wide) {
									left: calc(50% - 690px);
									height: 520px !important;
									width: auto !important;
								}
								@include media(1350px) {
									left: calc(50% - 940px);
								}
								@include media(1700px) {
									left: -10px;
	    						width: calc(100% + 20px) !important;
									height: auto !important;
								}
							}
						}
					}

					.card-slide-content-title {
						display: none;
					}

					.card-description {
						// background-color:$slide-text-bg-color;
						// @media(min-width:960px) {
						// 	background-color: transparent;
						// }

						.card-slide-description {
							bottom:0;
							position:relative;
							background-color:$slide-text-bg-color;
							font:$slide-text-font;
							padding:30px 40px 75px;
							margin:-40px 35px 0;
							color:$slide-text-color;
							min-height:230px;
							text-align: center;
              &:before {
                content: "";
                display: inline-block;
                height: 200px;
                width: 100%;
                position: absolute;
                bottom: -200px;
                left: 0px;
                background: $slide-text-bg-color;;
              }

							@media(min-width:$narrow) {
								// background:$slide-narrow-bg no-repeat 50% 50% / 380px 380px;
								height: 320px;
	    					width: 320px;
								top: calc(50% - 120px);
								left:60px;
								display: flex;
						    justify-content: center;
						    align-items: center;
								border-radius: 320px;
								position:absolute;
								padding: 50px 40px 70px;
                &:before {
                  display: none;
                }
							}

							@media(min-width:1600px) {
								left: calc(50%  - 800px + 70px);
							}

							p {
								font:$slide-text-font;
								// font-size: 1.5rem;
								margin: 0;

								@media(min-width:$narrow) {
									font:$slide-text-font-small;
								}
							}

							// @include media(960px) {
							// 	position:absolute;
							// 	padding: 50px 40px 70px;
							// }
							// @include media(1200px) {
							// 	position:absolute;
							// 	padding: 160px 40px;
							// }
						}
					}
				}
			}
		}

		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: normal;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}

	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:10px;
		width:100%;
		left: 0;

		.slick-nav button.slick-arrow {
			&.slick-prev {
				bottom: 0;
		    top: -50px;
				left: 10px;
			}
			&.slick-next {
				bottom: 0;
		    top: -50px;
				right: 10px;
			}
		}

		// @media(min-width:720px) {
		// 	left: 10px;
		// 	width: calc(100% - 20px);
		//
		// 	.slick-nav button.slick-arrow {
		// 		&.slick-prev {
		// 			left: 10px;
		// 		}
		// 		&.slick-next {
		// 			right: 10px;
		// 		}
		// 	}
		// }

		@include media($narrow) {
			width: 370px;
			left: 80px;
			top: 0;

			.slick-nav button.slick-arrow {
				&.slick-prev {
					left: 0;
					top: 50%;
				}
				&.slick-next {
					right: 0;
					top: 50%;
				}
			}

			.slick-nav .slick-dots {
		    bottom: calc(50% - 140px);
			}
		}

		@media(min-width:1600px) {
			left: calc(50%  - 800px + 80px);
		}
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;

		// @media (min-width:1600px) {
		// 	max-height: 450px;
		// 	overflow: hidden;
		//
		// 	.view-slider .view-content .slick-list .slick-track .views-row {
		// 		margin: 0;
		//
		// 		.card-image {
		// 			.card-slide-image {
		// 				position:relative;
		// 				img {
		// 					max-width:none;
		// 					position:relative;
		// 					width: 100%;
		//
		// 					@include media($narrow) {
		// 						left: -10px;
		// 						width: calc(100% + 20px);
		// 					}
		//
		// 					@include media($wide) {
		// 						left:calc(50% - 800px);
		// 						width: auto;
		// 					}
		//
		// 					@media (min-width:1600px) {
		// 						left:0;
		// 						width: 100%;
		// 					}
		// 				}
		// 			}
		// 		}
		//
		// 		.card-slide-description {
		// 			bottom:0;
		// 			position:relative;
		// 			background-color:$slide-text-bg-color;
		// 			font:$slide-text-font;
		// 			padding:17px 20px 55px 20px;
		// 			margin:0;
		// 			color:$slide-text-color;
		// 			min-height:66px;
		//
		// 			@include media($narrow) {
		// 				background-color:$slide-text-bg-color;
		// 				position:absolute;
		// 				padding:17px 20px;
		// 				max-width:75%;
		// 			}
		// 			@include media(1600px) {
		// 				top: calc(450px - 66px);
		// 				bottom:auto;
		// 				left: calc(50% - 800px + 20px);
		// 				max-width:50%;
		// 			}
		// 		}
		// 	}
		// }

		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}

		// .view-slider .view-footer {
		// 	@include media(1600px) {
		// 		top: calc(450px - 117px);
		// 		bottom:auto;
		// 		left: calc(50% - 800px + 20px);
		// 		max-width: calc(1600px - 40px);
		// 	}
		// }
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				@media(min-width:720px) {
					.card-description {
						background-color:$slide-text-bg-color;

						.card-slide-description {
							bottom:0;
							margin: 0;
					    height: auto;
					    width: auto;
					    border-radius: 0;
					    position: relative;
					    left: 0;
					    top: 0;
					    padding: 30px 20px 95px;
						}
					}
				}
			}

			@media(min-width:720px) {
				.view-footer {
					bottom: 30px;
			    width: calc(100% - 40px);
			    left: 20px;
					top: auto;

					.slick-dots {
						display: none;
						bottom: 14px;
					}

					.slick-nav button.slick-arrow {
						&.slick-prev {
							left: 0;
							top: 50%;
						}
						&.slick-next {
							right: 0;
							top: 50%;
						}
					}
				}
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}

	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
    margin-top: $vert-space;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:$vert-space;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}

	}
	.view-footer {
		text-align:center;
	}
}



