.credit,
.vid-description {
	background: white $ico-info-close no-repeat 100% 50% / 40px 40px;
	border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  float: right;
  font-size: 1rem;
  padding: 8px 60px 8px 20px;
  position: absolute;
  right: 0px;
  text-align: right;
  top: 10px;
  height: 40px;
  width: auto;
  border-radius: 40px;
	
	&:empty {
		display: none;
	}
	
	&:hover {
		background: white $ico-info-close-hover no-repeat 100% 50% / 40px 40px;
	}

	&.collapsed {
		background: transparent $ico-info no-repeat 100% 50% / 40px 40px;
		margin-right: 0;
		text-align: inherit;
		text-indent: -9999px;
		transition: background-color 0.5s linear;
		height: 40px;
		width: 40px;

		&:hover {
			background: transparent $ico-info-hover no-repeat 100% 50% / 40px 40px;
		}
	}
}