/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
	.views-row {
		margin-bottom:15px;
		margin-left: 10px;
	}
	
	.card-qa-answer {
		display: none;
		padding: 10px 10px 10px 40px;	
		box-sizing: border-box;
 
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		p {margin-bottom: 0;}
		text-decoration: none;
		font: $faq-question-font;
		color: $action-default;
		padding: 10px 0 10px 40px;
		line-height: 20px;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		// font-weight: normal;
		font-size: 1.222em;
		background: $ico-expand-blue no-repeat scroll 0 50% / 20px 20px transparent;
		&:hover {
			color:$middle-green;
			background: $ico-expand-green no-repeat scroll 0 50% / 20px 20px transparent;
		}

		&.active {
			// font-weight: normal;
			background: $ico-collapse-blue no-repeat scroll 0 50% / 20px 20px transparent;
			&:hover {
				color:$middle-green;
				background: $ico-collapse-green no-repeat scroll 0 50% / 20px 20px transparent;
			}
		}
	}
}


.section-row {
	&.palette-highlight {
		.view-faqs {
			.card-qa-answer {
				color:white;
			}
			.card-qa-question {
				color: white;
				background: $ico-expand-white no-repeat scroll 0 50% / 20px 20px transparent;
				&:hover {
					color:$legendary_purple;
					background: $ico-expand-dark no-repeat scroll 0 50% / 20px 20px transparent;
				}
				&.active {
					background: $ico-collapse-white no-repeat scroll 0 50% / 20px 20px transparent;
					&:hover {
						background: $ico-collapse-dark no-repeat scroll 0 50% / 20px 20px transparent;
					}
				}
			}
		}
	}
}